<template>
  <div>
    中心看板
    <el-button @click="jumpmap">跳转地图</el-button>

    
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
 
  methods: {
    jumpmap() {
      this.$router.push({ path: "../largesize" });
    },
   
  },
}
  

</script>

<style lang="less">

</style>